import { baseTheme } from '@jetshop/ui/BaseTheme';
import { createBreakpointHelpers } from '@jetshop/ui/utils/breakpoints';
const fontFamilies = {
  header: '"Lato", Helvetica, Arial, sans-serif;',
  body: '"Lato", Helvetica, Arial, sans-serif;',
  general: '"Lato", Helvetica, Arial, sans-serif;'
};

const typo = {
  fontWeightNormal: 400,
  fontWeightBold: 700,
  fontWeightLight: 200
};

const fontSize = {
  header: {
    nav: '1rem'
  },
  breadCrumb: '0.85rem',
  leftAside: '0.85rem'
};

const breakpoints = {
  labels: ['xs', 'sm', 'md', 'lg', 'xl'],
  breakpoints: ['20rem', '40rem', '50rem', '64rem', '75rem']
};

const colors = {
  ...baseTheme.colors,
  red: '#E82A2A',
  font: '#333',
  primary: '#B24748',
  active: '#600e0e',
  hover: '#7a2626',
  activeDarker: '#441f1f',
  primaryDarker: '#783838',
  secondary: '#666',
  buy: '#009900',
  border: '#EEE',
  link: '#85919B',
  loadingBar: '#FF6060',
  greys: [
    '#F0F0F0',
    '#DEDEDE',
    '#D5D5D5',
    '#C4C4C4',
    '#9A9A9A',
    '#777777',
    '#282828',
    '#F9F9F9',
    '#CCCCCC',
    '#b2b2b2'
  ],
  loading: '#d2d2d1',
  leftAside: {
    categoryBackgroundActive: '#ac5050',
    categoryBackground: '#FFFFFF',
    categoryText: '#85919b',
    categoryTextActive: '#FFFFFF',
    categoryTextHover: '#545e66',
    openSubListButtonActive: '#FFFFFF',
    openSubListButton: '#ac5050'
  },
  categoryPage: {
    boxBg: '#FFFFFF',
    border: '#EEEEEE',
    link: '#85919B',
    linkHover: '#545e66'
  },
  header: {
    searchIcon: '#B24748',
    searchIconHover: '#7a2626',
    cartButton: '#5FC151',
    cartButtonHover: '#54A02E'
  },
  footer: {
    background: '#222222',
    text: '#b2b2b2',
    linkHover: '#999898'
  },
  price: ['#c60f12', '#333333'],
  product: {
    name: '#85919B',
    nameHover: '#545e66',
    subName: '#999999',
    normalPrice: '#222222',
    discountPrice: '#c60f12',
    wrapBorder: '#eeeeee',
    descriptionHeader: '#222222',
    description: '#333333',
    link: {
      color: '#85919b',
      colorHover: '#545e66'
    },
    membershipLink: '#B24748'
  },
  breadCrumb: '#85919b',
  filter: {
    applyButton: '#88b86e',
    applyButtonHover: '#7ab05d',
    barBackground: '#ac5050'
  },
  errorPage: {
    title: '#c60f12',
    content: '#333333',
    body: '#333333',
    link: {
      color: '#85919B',
      colorHover: '#545e66'
    },
    button: {
      background: '#ac5050',
      backgroundHover: '#894040'
    }
  },
  notifyWhenBack: {
    button: {
      background: '#ac5050',
      backgroundHover: '#894040'
    },
    error: '#eb5757',
    posedBorder: '#bfbdbd'
  }
};

const general = {
  maxWidth: '1400px;'
};

const header = {
  background: colors.greys[6]
};

export const evaldsTheme = {
  ...baseTheme,
  ...createBreakpointHelpers(breakpoints.labels, breakpoints.breakpoints),
  ...breakpoints,
  fontSize,
  general,
  fontFamilies,
  colors,
  header,
  typo
};
