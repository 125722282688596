import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { injectGlobal } from 'react-emotion';

import { evaldsTheme } from './EvaldsTheme';

export const theme = evaldsTheme;

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes.node
};

injectGlobal`
  html, body {
    font-family: ${theme.fontFamilies.body};
    height: 100%;
    width: 100%;
  }
  /* apply a natural box layout model to all elements, but allowing components to change */
  html {
    position: relative;
    box-sizing: border-box;
    &.lock-scroll {
      position: fixed;
      height: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      -webkit-overflow-scrolling: auto;
      overflow: hidden;
      zoom: 100%

    }
  }

  body {
    font-size: 14px;
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  button {
    background: transparent;
  }
`;

export default Theme;
