import React from 'react';
import styled from 'react-emotion';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import theme from '@jetshop/ui/utils/theme';
import IntlContext from '@jetshop/intl/context';

const Input = styled('input')`
  border: 0px solid #fd9e2f;
  width: calc(100% - 2.5rem);
  background-color: #424141;
  font-size: 0.7875rem;
  outline: none;
  height: 2.5rem;
  padding: 1rem;
  color: #b1b1b1;
  border-radius: 0;
  display: block;
  transition: 0.5s;
  font-family: ${theme('fontFamilies.general')};
  &:focus {
    background-color: ${theme('colors.white')};
    color: ${theme('colors.black')};
  }
  ${({ theme }) => theme.below.lg} {
    width: 100%;
    height: 1.875rem;
  }
`;

const SearchInput = ({ isDirty, getInputProps }) => (
  <FlyoutTrigger id="searchFlyout" showCover={false}>
    {({ showTarget, hideTarget }) => (
      <IntlContext.Consumer>
        {t => (
          <Input
            type="search"
            {...getInputProps({
              onFocus: showTarget,
              refKey: 'innerRef'
            })}
            isDirty={isDirty}
            placeholder={t('Search the store')}
          />
        )}
      </IntlContext.Consumer>
    )}
  </FlyoutTrigger>
);

export default SearchInput;
