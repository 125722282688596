import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import { Price } from '@jetshop/ui/Price';
import theme from '@jetshop/ui/utils/theme';
import get from 'lodash.get';
import React, { Fragment, useContext } from 'react';
import styled from 'react-emotion';
import CartItem from './CartItem';
import CloseButton from '../ui/CloseButton';
import Button from '../ui/Button';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import cartQuery from './CartQuery.gql';

const Flyout = styled('div')`
  background: ${theme('colors.white')};
  color: ${theme('colors.black')};
  position: absolute;
  z-index: 999;
  right: 0;
  top: 0;
  bottom: 0;
  width: 22rem;
  max-width: 100%;
  overflow: hidden;
`;

const LightText = styled('span')`
  color: #828282;
  font-size: 0.875rem;
  font-family: ${theme('fontFamilies.body')};
  font-weight: normal;
`;

const Header = styled('header')`
  text-align: center;
  h2 {
    font-weight: 600;
    font-family: ${theme('fontFamilies.heavy')};
  }
  ${LightText} {
    display: block;
  }
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
`;

const ItemCount = ({ count }) => (
  <LightText>
    {t(
      `{
        count, plural,
        =0 {}
        one {1 item}
        other {{count} items}
            }`,
      { count }
    )}
  </LightText>
);

const CartItems = styled('section')`
  padding: 1rem;
  overflow: auto;
  position: absolute;
  top: 3.8rem;
  bottom: 8rem;
  left: 0;
  right: 0;
  h1 {
    text-align: center;
  }
`;

const Summary = styled('section')`
  background: ${theme('colors.white')};
  padding: 0rem;
  a {
    color: ${theme('colors.white')};
    :hover {
      color: ${theme('colors.white')};
    }
  }
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

const DeliverySummary = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 1rem 1rem 0 1rem;
`;

const TotalSummary = styled('div')`
  font-size: 1.25rem;
  font-weight: 600;
  font-family: ${theme('fontFamilies.heavy')};
  color: ${theme('colors.black')};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 1rem;
`;

const Checkout = styled(Button)`
  width: 50%;
  display: flex;
  background: ${theme('colors.header.cartButton')};
  :hover {
    background: ${theme('colors.header.cartButtonHover')};
  }
`;

const CloseCart = styled(Button)`
  width: 50%;
  display: flex;
  background: ${theme('colors.greys.4')};
  :hover {
    background: ${theme('colors.greys.5')};
  }
`;

const ButtonWrapper = styled('div')`
  width: 100%;
  display: flex;
`;

const FreeShipping = ({ cart }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const vat = selectedChannel.settings.pricesIncVat ? 'incVat' : 'exVat';
  const freeShippingLimit = get(selectedChannel, [
    'countrySettings',
    'shipping',
    'freeShippingLimit',
    vat
  ]);
  const productTotal = cart.data.cart.productTotal[vat];

  if (!freeShippingLimit) return null;
  return freeShippingLimit <= productTotal ? (
    <DeliverySummary>
      <LightText>{t('Delivery')}</LightText>
      <LightText>{t('Free for your order')}</LightText>
    </DeliverySummary>
  ) : (
    <DeliverySummary>
      <LightText>{t('Delivery')}</LightText>
      <LightText>
        {t.rich('You have { amount } left to free shipping', {
          amount: t.number(
            freeShippingLimit - productTotal,
            selectedChannel.currency.name,
            selectedChannel.currency.format.culture
          )
        })}
      </LightText>
    </DeliverySummary>
  );
};

const CartFlyoutView = ({ cart, modal, ...rest }) => {
  const items = get(cart, 'data.cart.items', []);
  const itemCount = get(cart, 'data.cart.totalQuantity', 0);
  const checkoutUrl = get(cart, 'data.cart.externalCheckoutUrl');
  const track = useTracker();

  return (
    <Flyout {...rest}>
      <Header>
        <h2>{t('Your cart')}</h2>
        <ItemCount count={itemCount} />
        <CloseButton onClick={modal.hideTarget}>&#x2715;</CloseButton>
      </Header>

      <CartItems>
        {items.length > 0 ? (
          items.map(item => <CartItem item={item} key={item.id} />)
        ) : (
          <h1>{t('Cart is empty.')}</h1>
        )}
      </CartItems>

      <Summary>
        <FreeShipping cart={cart} />
        <TotalSummary>
          <label>{t('Total')}</label>
          <Price price={cart.data.cart.productTotal} />
        </TotalSummary>
        <ButtonWrapper>
          <CloseCart onClick={modal.hideTarget}>
            {t('Continue shopping')}
          </CloseCart>
          {checkoutUrl && (
            <Checkout
              anchor={true}
              href={checkoutUrl}
              onClick={() => {
                track(trackCartCheckoutEvent({ cart: cart.data.cart }));
              }}
            >
              {t('Check out')}
            </Checkout>
          )}
        </ButtonWrapper>
      </Summary>
    </Flyout>
  );
};

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {cart =>
      cart.data.cart ? (
        <Fragment>
          {/* Flyout is used for desktop */}
          <FlyoutTarget id="cart-flyout">
            {flyout => <CartFlyoutView modal={flyout} cart={cart} {...props} />}
          </FlyoutTarget>

          {/* Drawer is used for mobile */}
          <DrawerTarget id="cart-drawer">
            {drawer => (
              <Drawer isOpen={drawer.isOpen} right>
                <CartFlyoutView modal={drawer} cart={cart} {...props} />
              </Drawer>
            )}
          </DrawerTarget>
        </Fragment>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
