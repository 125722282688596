import React, { Component } from 'react';
import styled from 'react-emotion';
import { Above } from '@jetshop/ui/Breakpoints';
import { Icon } from 'react-icons-kit';
import { check } from 'react-icons-kit/fa/check';

import theme from '@jetshop/ui/utils/theme';
import { Link } from 'react-router-dom';

import TopNav from './TopNav';

import { Query } from 'react-apollo';
import pageQuery from '../PageQuery.gql';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

//STYLE VARIABLES
const topBar = {
  height: {
    lg: '2.5rem',
    md: '3rem',
    sm: '3rem'
  }
};

const BackgroundWrapper = styled('div')`
  background-color: ${theme('colors.black')};
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TopBarWrapper = styled('div')`
  width: 100%;
  height: 100%;
  max-width: ${theme('general.maxWidth')};
  font-family: ${theme('fontFamilies.general')};
  font-weight: 700;
  font-size: 0.65625rem;
  height: ${topBar.height.lg};
  display: flex;
  align-items: center;

  ${theme('only.md')} {
    height: ${topBar.height.md};
  }

  ul.top-nav-list {
    display: block;
    min-width: 40%;
    max-width: 58%;

    li {
      display: inline-block;
    }

    li > *:not(div) {
      line-height: ${topBar.height.lg};
      font-size: 0.7rem;
      color: ${theme('colors.white')};

      ${theme('only.md')} {
        line-height: ${topBar.height.md};
      }
    }
  }
`;

const USPWrapper = styled('div')`
  display: inline-block;

  ul {
    li {
      display: inline-block;
      color: #b2b2b2;
      font-weight: bold;
      font-size: 10.5px;
      text-transform: uppercase;
      margin-right: 0.5rem;
      svg {
        margin-top: -0.2rem;
      }

      span.text {
        padding-left: 0.1875rem;
      }
    }
  }
`;

const TopNavWrapper = styled('div')`
  height: 100%;
  align-content: center;
`;

const Spacer = styled('div')`
  flex: 1;
`;

const StyledLink = styled(Link)`
  color: #e5e5e5;
  font-style: normal;
  font-weight: bold;
  font-size: 10.5px;
  line-height: normal;
  text-transform: uppercase;
`;

const A = styled('a')`
  color: #e5e5e5;
  font-style: normal;
  font-weight: bold;
  font-size: 10.5px;
  line-height: normal;
  text-transform: uppercase;
`;

const HeaderLink = ({ href, children }) => {
  if (href.indexOf('#') === 0) {
    return <A href={href}>{children}</A>;
  }

  return <StyledLink to={href}>{children}</StyledLink>;
};

const HeaderLinks = () => {
  const { loggedIn } = useAuth();

  return (
    <Query query={pageQuery} variables={{ id: 83 }}>
      {({ data }) => {
        if (!data || !data.page || !data.page.content) return null;
        return (
          <>
            <Shortcodes
              content={data.page.content}
              components={{ a: HeaderLink }}
            />
            <HeaderLink href="/login">
              {loggedIn ? 'MINA SIDOR' : 'LOGGA IN'}
            </HeaderLink>
          </>
        );
      }}
    </Query>
  );
};

export default class PreHeader extends Component {
  render() {
    return (
      <BackgroundWrapper>
        <TopBarWrapper>
          <TopNavWrapper>
            <TopNav left>
              <HeaderLinks />
            </TopNav>
          </TopNavWrapper>
          <Spacer />
          <Above
            breakpoint="xl"
            render={() => (
              <Query query={pageQuery} variables={{ id: 81 }}>
                {({ data }) => {
                  if (!data || !data.page || !data.page.content) return null;
                  return (
                    <USPWrapper>
                      <ul>
                        {data.page.content.split(/\n/).map((text, index) => (
                          <li key={index}>
                            <Icon icon={check} size={12} />
                            <span className="text">{text}</span>
                          </li>
                        ))}
                      </ul>
                    </USPWrapper>
                  );
                }}
              </Query>
            )}
          />
        </TopBarWrapper>
      </BackgroundWrapper>
    );
  }
}
