import styled from 'react-emotion';
import { default as BreadcrumbsComponent } from '@jetshop/ui/Breadcrumbs';

const Breadcrumbs = styled(BreadcrumbsComponent)`
  width: 100%;
  flex: 1 100%;
  margin-bottom: 1rem;
  font-size: 0.8125rem;
  li,
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.breadCrumb};
    font-size: ${({ theme }) => theme.fontSize.breadCrumb};
  }

  li {
    ::after {
      content: ' > ';
    }
  }
`;

export default Breadcrumbs;
