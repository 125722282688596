import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { default as StickyComponent } from 'react-stickynode';

const navBar = {
  fontSize: '14px',
  padding: '15px',
  height: {
    lg: '2.5rem',
    md: '2.5rem',
    sm: '2.5rem'
  }
};

const Menu = styled('div')`
  width: 100%;
  &:before {
    border-top: 5px solid #b24748;
    content: ' ';
    width: 100%;
    display: block;
    background: transparent;
  }
`;

const Wrapper = styled('div')`
  position: sticky;
  background: #000;
  top: 0;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: ${theme('general.maxWidth')};
    margin: 0 auto;
  }
`;

const MainMenuItem = styled('li')`
  list-style: none;
  font-family: ${theme('fontFamilies.header')};
  font-size: ${navBar.fontSize};

  line-height: ${navBar.height.lg};
  ${theme('only.md')} {
    line-height: ${navBar.height.md};
  }

  &:hover {
    background-color: #7a2626;
  }

  a {
    text-decoration: none;
    color: ${theme('colors.white')};
    display: block;
    padding: 0 0.875rem;
    &.active {
      font-weight: ${theme('typo.fontWeightBold')};
      background-color: #600e0e;
    }

    &.selected {
      text-decoration: underline;
    }
  }
`;

const Sticky = styled(StickyComponent)`
  width: 100%;
  &.active {
    .sticky-inner-wrapper {
      z-index: 5;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    }
  }
`;

const MenuContainer = ({ queryData }) => {
  return (
    <Menu>
      <UIMenuContainer>
        {props => (
          <Sticky>
            <Wrapper>
              <ul>
                {queryData &&
                  queryData.categories &&
                  queryData.categories.map(cat => (
                    <MainMenuItem key={cat.id}>
                      <CategoryLink category={cat}>{cat.name}</CategoryLink>
                    </MainMenuItem>
                  ))}
              </ul>
            </Wrapper>
          </Sticky>
        )}
      </UIMenuContainer>
    </Menu>
  );
};

export default MenuContainer;
