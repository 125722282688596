import React, { Component } from 'react';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import MobileCategoriesComp from './Categories/MobileCategories';
import t from '@jetshop/intl';
import CloseButton from '../../ui/CloseButton';

const FlyoutHeader = styled('h2')`
  position: relative;
  text-align: center;
  font-weight: ${theme('typo.fontWeightBold')};
  font-family: ${theme('fontFamilies.heavy')};
  padding: 1rem;
  border-bottom: 1px solid #e8e8e8;
`;

const Flyout = styled('div')`
  background: ${theme('colors.white')};
  color: ${theme('colors.black')};
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
`;

const MobileMenuWrapper = styled('div')`
  padding: 1rem;
`;

const MobileCategories = styled(MobileCategoriesComp)``;

let prevScrollY = 0;

const MobileMenuView = ({ drawer, categories }) => {
  if (categories === undefined) {
    return null;
  }

  return (
    <Flyout>
      <FlyoutHeader>
        {t('Menu')}
        <CloseButton onClick={drawer.hideTarget}>&#x2715;</CloseButton>
      </FlyoutHeader>
      <MobileMenuWrapper>
        <MobileCategories
          categories={categories}
          closeMenu={drawer.hideTarget}
        />
      </MobileMenuWrapper>
    </Flyout>
  );
};

export default class MobileMenu extends Component {
  render() {
    const { categories } = this.props;

    return (
      <DrawerTarget id="mobile-drawer">
        {drawer => {
          if (
            typeof document !== 'undefined' &&
            typeof window !== 'undefined'
          ) {
            if (drawer.isOpen) {
              prevScrollY = window.scrollY;
            }
            const root = document.querySelector('html');

            const style =
              'top:' +
              prevScrollY * -1 +
              'px;' +
              'height:' +
              document.body.scrollHeight +
              'px;';
            root.style = style;
            root.classList.toggle('lock-scroll', drawer.isOpen);
            if (!drawer.isOpen) {
              root.style = '';
              if (prevScrollY > 0) {
                window.scrollTo(0, prevScrollY);
                prevScrollY = 0;
              }
            }
          }
          return (
            <Drawer isOpen={drawer.isOpen} left>
              <MobileMenuView drawer={drawer} categories={categories} />
            </Drawer>
          );
        }}
      </DrawerTarget>
    );
  }
}
