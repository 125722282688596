import React from 'react';
import styled from 'react-emotion';
import t from '@jetshop/intl';
import MaxWidth from '../MaxWidth';
import FooterSection from './FooterSection';
import FooterSocialButton from './FooterSocialButton';
import { ReactComponent as FacebookIcon } from '../../../svg/Facebook.svg';
import { ReactComponent as YoutubeIcon } from '../../../svg/Youtube.svg';
import { ReactComponent as InstagramIcon } from '../../../svg/Instagram.svg';
import NewsletterField from '../../Cart/Newsletter/NewsletterField';

import { useQuery } from '@apollo/react-hooks';
import pageQuery from '../PageQuery.gql';
import { Shortcodes } from '@jetshop/flight-shortcodes';

const Container = styled('section')`
  background: ${({ theme }) => theme.colors.footer.background};
  padding: 0;
  margin: 0;
`;

const Wrapper = styled(MaxWidth)`
  text-align: left;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.footer.text};

  h2 {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

const PoweredByWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 3rem;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  p {
    display: inline;
  }
`;

const Section = styled(FooterSection)``;

const Newsletter = () => (
  <FooterSection sectionTitle={t('Newsletter')} className="newsletter">
    <NewsletterField />
  </FooterSection>
);

const SocialIcon = ({ type, link, text }) => {
  let styledIcon;

  if (type === 'facebook') {
    styledIcon = <StyledFacebookIcon />;
  } else if (type === 'youtube') {
    styledIcon = <StyledYoutubeIcon />;
  } else if (type === 'instagram') {
    styledIcon = <StyledInstagramIcon />;
  }

  return (
    <FooterSocialButton
      className={type}
      buttonText={text}
      hyperLink={link}
      buttonIcon={styledIcon}
    />
  );
};

const StyledFacebookIcon = styled(FacebookIcon)`
  fill: ${({ theme }) => theme.colors.white};
`;
const StyledYoutubeIcon = styled(YoutubeIcon)`
  fill: ${({ theme }) => theme.colors.white};
`;
const StyledInstagramIcon = styled(InstagramIcon)`
  fill: ${({ theme }) => theme.colors.white};
`;

const boxPadding = '1rem';

const WrapFooterNav = styled('div')`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem 1.25rem;
  padding-top: 3.125rem;
  padding-bottom: 1.375rem;
  font-family: ${({ theme }) => theme.fontFamilies.general};

  > * {
    width: 25%;
    padding: 0 1.5625rem 0 0;
  }

  strong {
    font-weight: bold;
  }

  .contact {
    dl {
      font-size: 0.875rem;
      line-height: 1.6;
      list-style-position: outside;
      margin-bottom: 0.75rem;
      width: 100%;
    }
    dl > dt {
      font-weight: bold;
      clear: left;
      float: left;
      width: 30%;
    }
    dl > dd {
      clear: right;
      width: 70%;
    }
  }

  .guarantee {
    ul {
      list-style: disc;
      padding-left: 0.9375rem;
    }
    li {
      margin-bottom: 0.3125rem;
    }
  }

  .facebook {
    background: #3b5998;
    color: ${({ theme }) => theme.colors.white};
    :hover {
      background: #4c70ba;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .youtube {
    background: #cc181e;
    color: ${({ theme }) => theme.colors.white};
    :hover {
      background: #e73036;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .instagram {
    background: #3f729b;
    color: ${({ theme }) => theme.colors.white};
    :hover {
      background: #548cb9;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .newsletter {
    padding-top: 1rem;
  }

  ${({ theme }) => theme.below.lg} {
    justify-content: start;
    align-items: end;
    flex-wrap: wrap;
    > * {
      width: 50%;
    }

    .about {
      order: 1;
      padding: ${boxPadding};
    }

    .contact-wrapper {
      order: 2;
      padding: ${boxPadding};
    }

    .guarantee {
      order: 3;
      padding: ${boxPadding};
    }

    .social {
      order: 4;
      padding: ${boxPadding};
    }

    .newsletter {
      order: 5;
    }
  }

  ${({ theme }) => theme.below.md} {
    > * {
      width: 50%;
    }
  }

  ${({ theme }) => theme.below.sm} {
    > * {
      width: 100%;
    }
  }
`;

const FooterContent = () => {
  const { loading, error, data } = useQuery(pageQuery, {
    variables: { id: 93 }
  });

  if (loading) return <p>{t('Loading...')}</p>;
  if (error || !data || !data.page.content) return null;

  return (
    <Shortcodes
      content={data.page.content}
      components={{
        Section,
        SocialIcon,
        Newsletter
      }}
    />
  );
};

const Footer = () => (
  <Container id="footer">
    <Wrapper>
      <WrapFooterNav>
        <FooterContent />
      </WrapFooterNav>
    </Wrapper>
    <PoweredByWrapper>
    </PoweredByWrapper>
  </Container>
);

export default Footer;
