import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';

export default {
  apolloConfig: {
    disableGateway: true,
    shopid: process.env.REACT_APP_SHOP_ID || 'evaldsmtb',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.se',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    channelsQuery,
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          EUR: { style: 'currency', currency: 'EUR' },
          SEK: { style: 'currency', currency: 'SEK' },
          USD: { style: 'currency', currency: 'USD' },
          GBP: { style: 'currency', currency: 'GBP' },
          NKR: { style: 'currency', currency: 'NKR' },
          AUD: { style: 'currency', currency: 'USD' },
        },
      },
    },
  },
  channelOverrides: {},
  structuredData: {
    disableDefaultProductData: false,
  },
  loginPath: '/login',
  deprecations: { useOldProductVariants: true, useOldFilterAPI: true },
};
