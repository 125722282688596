import React from 'react';
import styled, { css } from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';

export const List = styled('ul')`
  ${props =>
    props.left
      ? css`
          left: 0;
          > li:first-child {
            padding-left: 6px;
          }
        `
      : css`
          right: 0;
          justify-content: flex-end;
          > li:last-child {
            padding-right: 0;
          }
        `};
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 100%;

  > li:first-child {
    ${theme('below.sm')} {
      display: inline-block;
    }
  }

  ${theme('below.sm')} {
    position: static;
    display: inline-block;
    width: 23%;
    height: 64px;
    background-color: ${({ theme }) => theme.colors.greys[6]};
  }
`;

const ListItem = styled('li')`
  list-style: none;
  color: ${({ theme }) => theme.colors.white};
  display: block;
  height: 100%;
  display: flex;
  align-items: center;

  a {
    white-space: nowrap;
    text-decoration: none;
    text-transform: uppercase;
    ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 0.3125rem;
      background: ${({ theme }) => theme.colors.primary};
      display: block;
      left: 0;
      top: 0;
      transition: 0.3s;
      transform: translateY(-100%);
    }
    :hover {
      background: #282828;
      ::before {
        transform: scale(1);
      }
    }
    transition: 0.3s;
    position: relative;
    padding: 0 0.7rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export default ({ children, ...rest }) => (
  <List {...rest}>
    {React.Children.map(children, item => (
      <ListItem fontSize={1}>{item}</ListItem>
    ))}
  </List>
);
