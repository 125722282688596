import React, { createContext, useMemo, useContext } from 'react';
import SettingsQuery from './SettingsQuery.gql';
import { useQuery } from 'react-apollo';

const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
  const { data } = useQuery(SettingsQuery);

  const value = useMemo(() => {
    if (data) {
      try {
        const settings = data.page.head.metaTags.find(
          tag => tag.name === 'keywords'
        );
        return JSON.parse(settings.content);
      } catch (e) {
        console.log('Failed to parse settings.', e);
      }
    }

    return {};
  }, [data]);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);

export default SettingsProvider;
