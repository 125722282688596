import latoEotBold from './lato-v15-latin-700.eot';
import latoSvgBold from './lato-v15-latin-700.svg';
import latoTtfBold from './lato-v15-latin-700.ttf';
import latoWoffBold from './lato-v15-latin-700.woff';
import latoWoff2Bold from './lato-v15-latin-700.woff2';
import latoEot from './lato-v15-latin-regular.eot';
import latoSvg from './lato-v15-latin-regular.svg';
import latoTtf from './lato-v15-latin-regular.ttf';
import latoWoff from './lato-v15-latin-regular.woff';
import latoWoff2 from './lato-v15-latin-regular.woff2';
import { injectGlobal } from 'emotion';

// Google Fonts downloaded from http://google-webfonts-helper.herokuapp.com/fonts/sarabun?subsets=latin

export default function loadCss() {
  return injectGlobal`
  /* Regular font */
  @font-face {
    font-family: 'Lato';
    src: url(${latoEot}); /* IE9 Compat Mode */
    src: local('Lato Regular'), local('Lato-Regular'),
        url(${latoWoff2}) format('woff2'),
        url(${latoWoff}) format('woff'),
        url(${latoTtf}) format('truetype'),
        url(${latoSvg}) format('svg'); /* Legacy iOS */
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  /* Bold font */
  @font-face {
    font-family: 'Lato';
    src: url(${latoEotBold}); /* IE9 Compat Mode */
    src: local('Lato Bold'), local('Lato-Bold'),
        url(${latoWoff2Bold}) format('woff2'),
        url(${latoWoffBold}) format('woff'),
        url(${latoTtfBold}) format('truetype'),
        url(${latoSvgBold}) format('svg'); /* Legacy iOS */
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`;
}
