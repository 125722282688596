import Accordion, { AccordionContent } from '@jetshop/ui/Accordion/Accordion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import styled, { css } from 'react-emotion';
import { Link } from 'react-router-dom';

import { ReactComponent as CarrotSvg } from '../../../../svg/Carrot.svg';
import { Query } from 'react-apollo';
import pageQuery from '../../PageQuery.gql';
import { Shortcodes } from '@jetshop/flight-shortcodes';

const hasChildCategories = category =>
  category.subcategories && category.subcategories.length > 0;

const activeCategoryLink = css`
  font-weight: 600;
`;

const handleClickAndPreventDefault = (e, handleClick, i) => {
  e.preventDefault();
  handleClick(i);
};

const Wrapper = styled('div')`
  a {
    padding: 0.6rem 0;
    font-size: 1.1rem;
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;
    font-weight: bold;
    display: block;
    white-space: normal;
    + div {
      margin-left: 1rem;
      a {
        font-weight: normal;
        color: ${({ theme }) => theme.colors.black};
      }
    }
    &:hover {
      color: ${({ theme }) => theme.colors.black};
    }
  }
  .child {
    margin-left: 0;
  }

  .stayHidden {
    height: 0;
  }
`;

const Carrot = styled(CarrotSvg)`
  transform: scale(1) rotate(-90deg);
  float: right;
  margin-top: 0.4rem;
  margin-left: 1rem;
  fill: ${({ theme }) => theme.colors.primary};
  transition: transform 0.3s;
  &.open {
    transform: scale(1) rotate(0deg);
  }
`;

const ContentWrapper = styled('div')`
  display: block;
  &.stayHidden {
    display: none;
  }

  &.brands > div {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    a {
      width: 50%;
      padding: 0.6rem 1rem;
    }
  }
`;

const A = styled('a')``;

const Container = styled('div')`
  > ul {
    border-top: 2px solid #ac5050;
    > li {
      padding: 0.5rem 0;
      a:hover {
        color: inherit;
      }
    }
  }
`;

const HeaderLink = ({ href, children }) => {
  if (href.indexOf('#') === 0) {
    return (
      <li>
        <A href={href}>{children}</A>
      </li>
    );
  }

  return (
    <li>
      <Link to={href}>{children}</Link>
    </li>
  );
};

const HeaderLinks = () => (
  <Query query={pageQuery} variables={{ id: 83 }}>
    {({ data }) => {
      if (!data || !data.page || !data.page.content) return null;
      return (
        <Shortcodes
          content={data.page.content}
          components={{ a: HeaderLink }}
        />
      );
    }}
  </Query>
);

const MobileCategories = ({ categories, closeMenu, child }) => {
  return (
    <Accordion single className="Accordion">
      {({ handleClick, openIndexes }) =>
        categories.map(category => {
          if (category.name === 'Att resa med barn' && category.level === 1) {
            return null;
          }

          const isOpen = openIndexes.includes(category.id);
          const isBrands = category.name.toLowerCase() === 'varumärken';
          const isSubCategory = hasChildCategories(category);
          const baseClassName = [];

          if (!isOpen) {
            baseClassName.push('stayHidden');
          }

          if (isBrands) {
            baseClassName.push('brands');
          }

          return (
            <Wrapper key={category.id} className={child && 'child'}>
              {/* // Use AccordionContent */}
              <CategoryLink
                className={isOpen ? activeCategoryLink : ''}
                onClick={e =>
                  // If this category has child categories, we want to open them up with `handleClick`
                  // Otherwise, we want to follow the link and `closeMenu`
                  isSubCategory
                    ? handleClickAndPreventDefault(e, handleClick, category.id)
                    : closeMenu()
                }
                category={category}
              >
                {category.name}
                {isSubCategory && <Carrot className={isOpen && 'open'} />}
              </CategoryLink>
              <ContentWrapper className={baseClassName.join(' ')}>
                <AccordionContent isOpen={isOpen}>
                  <SubCategories category={category} closeMenu={closeMenu} />
                </AccordionContent>
              </ContentWrapper>
            </Wrapper>
          );
        })
      }
    </Accordion>
  );
};

const MenuItems = ({ categories, closeMenu, child }) => {
  return (
    <Container>
      <MobileCategories
        closeMenu={closeMenu}
        categories={categories}
        child={child}
      />
      <ul>
        <HeaderLinks />
      </ul>
    </Container>
  );
};

const SubCategories = ({ category, closeMenu }) => {
  if (category === undefined || category.subcategories === undefined) {
    return null;
  }

  if (category.name.toLowerCase() === 'att resa med barn') {
    category.primaryRoute = {
      id: '/att-resa-med-barn',
      path: '/att-resa-med-barn',
      __typename: 'Route'
    };
  }

  return category.subcategories.map(childCat => {
    if (hasChildCategories(childCat)) {
      return (
        <MobileCategories
          categories={[childCat]}
          closeMenu={closeMenu}
          key={childCat.id}
          child
        />
      );
    }
    return (
      <CategoryLink onClick={closeMenu} category={childCat} key={childCat.id}>
        {childCat.name}
      </CategoryLink>
    );
  });
};

export default MenuItems;
