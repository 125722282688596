import React, { Component } from 'react';
import styled from 'react-emotion';

const SocialButton = styled('a')`
  display: block;
  position: relative;
  margin-bottom: 0.625rem;
  text-transform: uppercase;
  font-size: 1.5em;
  text-align: center;
  padding: 0.625rem 1.875rem;
  line-height: 2.5rem;
  transition: 0.4s;
  align-items: center;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    height: 2rem;
    width: 2rem;
  }
`;

const FloatLeft = styled.span`
  float: left;
  padding-left: 0.875rem;
`;

const TextWrap = styled.span`
  padding-left: 2rem;
`;

class FooterSocialButton extends Component {
  render() {
    const props = {
      className: '',
      buttonIcon: '',
      buttonText: '',
      ...this.props
    };

    return (
      <SocialButton className={props.className} href={props.hyperLink}>
        <FloatLeft>{props.buttonIcon}</FloatLeft>
        <TextWrap>{props.buttonText}</TextWrap>
      </SocialButton>
    );
  }
}

export default FooterSocialButton;
