import React from 'react';
import styled from 'react-emotion';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import t, { Intl } from '@jetshop/intl';

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  max-width: 31rem;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.white};
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  flex: 0 1 20rem;

  ${({ theme }) => theme.above.md} {
    margin-bottom: 2rem;
  }

  form,
  input {
    height: 100%;
    width: 100%;
  }

  input {
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 4rem 0 1rem;
    border: 1px solid #bfbdbd;
  }
  button {
    all: unset;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.header.cartButton};
    border: 0;
    outline: none;
    position: absolute;
    right: 0rem;
    text-align: right;
    padding: 0 0.5rem;
    top: 0;
    height: 100%;
    cursor: pointer;
    :hover {
      background: ${({ theme }) => theme.colors.header.cartButtonHover};
    }
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #${({ theme }) => theme.colors.primary};
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => (
          <React.Fragment>
            {this.state.error ? (
              <Error>
                {t(
                  'Something went wrong. Please check your email and try again.'
                )}
              </Error>
            ) : (
              <h2>{t('Join the newsletter')}</h2>
            )}
            <Wrapper>
              {/* Show the completion success message once signed up, otherwise show the form */}
              {this.state.completed ? (
                <p>{t('You are now subscribed')}</p>
              ) : (
                <form onSubmit={submitEmail(this.state.email, subscribe)}>
                  <Intl>
                    {/* This is necessary to use t for the placeholder string */}
                    {t => (
                      <input
                        type="email"
                        placeholder={t('Enter email address')}
                        onChange={this.updateEmail}
                      />
                    )}
                  </Intl>
                  <button
                    style={this.state.email === '' ? { opacity: 0.5 } : null}
                  >
                    {/* Display 'Submitting…' while the form is posting */}
                    {result.loading ? t('Submitting…') : t('Submit')}
                  </button>
                </form>
              )}
            </Wrapper>
          </React.Fragment>
        )}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
