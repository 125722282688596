import React from 'react';
import styled, { css } from 'react-emotion';
import t from '@jetshop/intl';

import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import UISuggestedTerm from '@jetshop/ui/Search/SuggestedTerm';

const flyout = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 2.7rem;
  background: white;
  padding: 1rem;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.07);
`;

const resultStyles = css`
  h2 + ul {
    margin-top: 1em;
  }

  h2 {
    font-weight: bold;
  }

  ul + h2 {
    margin-top: 1.5em;
  }
`;

const SuggestedTerm = styled(UISuggestedTerm)`
  list-style: none;
  margin-bottom: 1rem;
  :last-child {
    margin-bottom: 0;
  }
  a {
    color: ${({ theme }) => theme.colors.black};
    display: block;
    :hover {
      color: ${({ theme }) => theme.colors.black};
      opacity: 0.7;
    }
  }
`;

const SearchFlyout = searchProps => {
  const products = searchProps.result?.products;
  const categories = searchProps.result?.categories;

  if (
    !searchProps.result ||
    (!searchProps.loading &&
      products.result.length === 0 &&
      categories?.result.length === 0)
  )
    return null;

  return (
    <FlyoutTarget id="searchFlyout">
      {flyoutProps =>
        searchProps.isOpen &&
        flyoutProps.isOpen &&
        searchProps.isDirty && (
          <div
            className={`search-flyout ${flyout}`}
            {...searchProps.getFlyoutProps()}
          >
            {searchProps.loading ? (
              t('Loading...')
            ) : (
              <div className={resultStyles}>
                {products?.result?.length > 0 && (
                  <>
                    <h2>{t('Products')}</h2>
                    <ul style={searchProps.loading ? { opacity: 0.5 } : null}>
                      {products.result.map(item => (
                        <SuggestedTerm
                          term={searchProps.term}
                          onClick={searchProps.closeSearch}
                          key={item.id}
                          item={item}
                          product={item}
                        />
                      ))}
                    </ul>
                  </>
                )}
                {categories?.result?.length > 0 && (
                  <>
                    <h2>{t('Categories')}</h2>
                    <ul style={searchProps.loading ? { opacity: 0.5 } : null}>
                      {categories.result.map(item => (
                        <SuggestedTerm
                          term={searchProps.term}
                          onClick={searchProps.closeSearch}
                          key={item.id}
                          item={item}
                          product={item}
                        />
                      ))}
                    </ul>
                  </>
                )}
              </div>
            )}
          </div>
        )
      }
    </FlyoutTarget>
  );
};

export default SearchFlyout;
