import { Above, Below, Between } from '@jetshop/ui/Breakpoints';
import theme from '@jetshop/ui/utils/theme';
import t from '@jetshop/intl';
import React, { Component, Fragment, useState } from 'react';
import { Query, useQuery } from 'react-apollo';
import styled, { css } from 'react-emotion';
import { Link } from 'react-router-dom';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import PreHeader from './PreHeader';
import { SearchField } from './Search/';
import MenuContainer from './ThreeTierMenu/MenuContainer';
import MobileMenu from './MobileMenu';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';

import { ReactComponent as Menu } from '../../../svg/Menu.svg';
import { ReactComponent as Search } from '../../../svg/Search.svg';

import { default as StickyComponent } from 'react-stickynode';

import ToastContainerWrapper from './ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';

import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import autocompleteQuery from './AutocompleteQuery.gql';

const StyledToastContainer = styled(ToastContainer)`
  ${theme('above.sm')} {
    width: unset;
  }
`;

const Container = styled('header')`
  position: relative;
  background: ${theme('header.background')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;
  margin-bottom: 0.75rem;
  ${theme('below.md')} {
    margin-bottom: 0px;
  }
  a {
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

const MenuButton = styled('button')`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: transparent;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0.2888rem 0.75rem;
  > svg {
    height: 1.125rem;
  }
  ${({ theme }) => theme.below.md} {
    padding: 0.688rem 1rem;
  }
`;

const StoreName = styled('h1')`
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  margin: 1.125rem 1.25rem;
  color: inherit;
  ${theme('below.lg')} {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 12px;
  }
  a {
    display: flex;
    text-decoration: none;
    color: inherit;
  }
`;

const Spacer = styled('div')`
  flex: 1;
  ${({ theme }) => theme.below.lg} {
    padding: 0;
  }
`;

const Middle = styled('div')`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  ${({ theme }) => theme.below.sm} {
    justify-content: center;
  }
  align-items: center;
  max-width: ${theme('general.maxWidth')};
  padding-top: 0.875rem;
  padding-bottom: 0.2rem;
  background: url('//www.evaldsmtb.se/m1/production/images/responsive-base/drev.png')
    center right no-repeat;
  background-size: auto 160%;
`;

const MobileNav = styled('nav')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #232323;
  height: 54px;
  border-bottom: 5px solid #b24748;
  > * {
    &:first-child {
      border-right: 1px solid #000;
    }
  }
  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const Text = styled('span')`
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  font-size: 1.125rem;
  margin: 0.4rem;
`;

const SearchButton = styled(MenuButton)`
  padding: 0 1rem;
`;

const Sticky = styled(StickyComponent)`
  width: 100%;
  &.active {
    .sticky-inner-wrapper {
      transform: none;
      z-index: 5;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
    }
  }
`;

const Header = ({}) => {
  const { data } = useQuery(homeCategoriesQuery, { variables: { levels: 1 } });

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const toggleSearch = () => setSearchOpen(open => !open);

  return (
    <Container>
      <ToastContainerWrapper>
        <StyledToastContainer
          toastClassName={css`
            padding: 0px;
            cursor: auto;
            font-family: inherit;
          `}
          autoClose={false}
          hideProgressBar
          closeButton={false}
          closeOnClick={false}
        />
      </ToastContainerWrapper>
      <Above breakpoint="md" render={() => <PreHeader />} />
      <Above
        breakpoint="lg"
        render={() => (
          <Fragment>
            <Middle>
              <StoreName>
                <Link to="/">
                  <img
                    alt="logo"
                    src="//www.evaldsmtb.se/Default/images/logo.png"
                  />
                </Link>
              </StoreName>
              <SearchField
                autocompleteQuery={autocompleteQuery}
                onCancel={() => {}}
                onOpen={() => {}}
                isOpen={true}
                alwaysOpen={true}
              />
              <Spacer />
              <Above breakpoint="xl" render={() => <CartButton />} />
              <CartFlyout />
            </Middle>
          </Fragment>
        )}
      />
      <Above
        breakpoint="xl"
        render={() => <MenuContainer queryData={data} />}
      />
      <Below
        breakpoint="xl"
        render={() => (
          <Fragment>
            <Sticky>
              <MobileNav>
                <DrawerTrigger preventOverflow={true} id="mobile-drawer">
                  {drawer => (
                    <MenuButton
                      onClick={
                        drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                      }
                    >
                      <Menu />
                      <Between breakpoints={['md', 'xl']}>
                        <Text>{t('menu')}</Text>
                      </Between>
                    </MenuButton>
                  )}
                </DrawerTrigger>
                <Below breakpoint={'lg'}>
                  <Fragment>
                    <SearchButton onClick={toggleSearch}>
                      <Search />
                      <Between breakpoints={['md', 'lg']}>
                        <Text>{t('search')}</Text>
                      </Between>
                    </SearchButton>
                    {searchOpen ? (
                      <SearchField
                        autocompleteQuery={autocompleteQuery}
                        onCancel={toggleSearch}
                        onOpen={toggleSearch}
                        isOpen={searchOpen}
                      />
                    ) : (
                      <StoreName>
                        <Link to="/">
                          <img
                            style={{ height: '24px' }}
                            alt="logo"
                            src="//www.evaldsmtb.se/Default/images/logo.png"
                          />
                        </Link>
                      </StoreName>
                    )}
                  </Fragment>
                </Below>
                <Spacer />
                <CartButton />
              </MobileNav>
            </Sticky>
            <MobileMenu
              isOpen={mobileMenuOpen}
              categories={data ? data.categories : []}
            />
          </Fragment>
        )}
      />
      <CartFlyout />
    </Container>
  );
};

export default Header;
