import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import t from '@jetshop/intl';
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import cartQuery from './CartQuery.gql';

const Button = styled('button')`
  background: ${theme('colors.header.cartButton')};
  color: ${theme('colors.white')};
  border: 0;
  outline: none;
  position: relative;
  font-size: 1rem;
  display: inline-block;
  white-space: nowrap;
  display: flex;
  padding: 10px 15px;
  border-radius: 2px;
  width: 252px;
  align-items: center;
  justify-content: space-between;
  ${theme('above.md')} {
    margin: 0px 30px;
  }
  ${theme('below.xl')} {
    background: transparent;
    padding: 0;
    width: auto;
  }

  svg {
    fill: ${theme('colors.white')};
    height: 18px;
    margin-right: 10px;
  }
`;

const CartLabel = styled('label')`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${theme('colors.white')};
  cursor: pointer;
  font-weight: 900;
`;

const CartTotal = styled('p')`
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: normal;
  color: rgba(255, 255, 255, 0.7);
`;

const IconTotalWrapper = styled('span')`
  display: flex;
  flex-direction: row;
  margin-right: 12px;
`;

const CartButton = () => (
  <Fragment>
    <CartProvider query={cartQuery}>
      {result => {
        // Set items in cart to the API result.
        // If the result is undefined, fall back to 0
        const itemsInCart = get(result, 'data.cart.totalQuantity', 0);

        return (
          <DrawerTrigger preventOverflow={true} id="cart-drawer">
            {drawer => (
              <>
                <Button
                  data-testid="header-cart"
                  onClick={
                    drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                  }
                >
                  <IconTotalWrapper data-testid="cart-button">
                    <Cart />
                    <Above breakpoint="md">
                      <CartTotal>
                        {t('Total: ')} {itemsInCart}
                      </CartTotal>
                    </Above>
                  </IconTotalWrapper>
                  <CartLabel>
                    <Above breakpoint="md">{t('cart')}</Above>
                  </CartLabel>
                </Button>
              </>
            )}
          </DrawerTrigger>
        );
      }}
    </CartProvider>
  </Fragment>
);

export default CartButton;
