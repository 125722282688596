import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import PreviewRoute from '@jetshop/core/components/DynamicRoute/PreviewRoute';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import React from 'react';
import Helmet from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LogInPage from './Auth/LogInPage';
import NotFound from './NotFoundPage';
import Theme from './Theme';
import routeQuery from './RouteQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import loadFontCss from './fonts/loadFontCss';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';

import loadable from '@loadable/component';
import LoadingPage from './LoadingPage';

import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import SettingsProvider from './Settings';

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: LoadingPage
});

const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: LoadingPage
  }
);

const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: LoadingPage
  }
);

const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: LoadingPage
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: LoadingPage
});

const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: LoadingPage
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: LoadingPage
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: LoadingPage
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: LoadingPage
});

const Shop = () => {
  return (
    <GenericError>
      <ModalProvider>
        <SettingsProvider>
          <Theme>
            <Container>
              <LoadingBar />
              <CustomFont primaryFont="Lato" injectCss={loadFontCss} />
              <Helmet titleTemplate="%s" defaultTitle="evalds.se" />
              <Header />
              <Content>
                <PaginationProvider defaultProductsPerPage={24}>
                  <Switch>
                    <Route exact path="/" component={LoadableStartPage} />
                    <Route path="/search" component={LoadableSearchPage} />
                    <Route path="/signup" component={LoadableSignUpPage} />
                    <Route path="/login" component={LogInPage} />
                    <Route path="/my-pages" component={LoadableMyPages} />
                    <Route
                      exact
                      path="/forgot-password"
                      component={ForgotPassword}
                    />
                    <Route
                      path="/reset-password/:token"
                      component={ResetPassword}
                    />
                    <Route
                      path="/preview"
                      component={props => (
                        <PreviewRoute
                          productPage={LoadableProductPage}
                          categoryPage={LoadableCategoryPage}
                          productQuery={ProductPreviewQuery}
                          categoryQuery={CategoryPreviewQuery}
                          StartPage={LoadableStartPage}
                          {...props}
                        />
                      )}
                    />
                    <DynamicRoute
                      routeQuery={routeQuery}
                      productPage={LoadableProductPage}
                      categoryPage={LoadableCategoryPage}
                      contentPage={LoadableContentPage}
                      notFoundPage={NotFound}
                      LoadingPage={LoadingPage}
                    />
                  </Switch>
                </PaginationProvider>
              </Content>
              <Footer />
              <ModalRoot />
              <ScrollRestorationHandler />
            </Container>
          </Theme>
        </SettingsProvider>
      </ModalProvider>
    </GenericError>
  );
};

export default Shop;
