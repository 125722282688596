import { Above } from '@jetshop/ui/Breakpoints';
import React from 'react';
import styled from 'react-emotion';
import posed from 'react-pose';
import { ReactComponent as Icon } from './Search.svg';

const PosedContainer = posed('div')({});

const Container = styled(PosedContainer)`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  height: 1rem;
  text-align: right;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  background: ${({ theme }) => theme.colors.header.searchIcon};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.below.lg} {
    display: none;
  }
  :hover {
    background: ${({ theme }) => theme.colors.header.searchIconHover};
  }
`;

const SearchIcon = styled(Icon)`
  height: 100%;
`;
const IconWrapper = styled('div')`
  display: inline-block;
  height: 1rem;
  max-height: 100%;
  ${({ theme }) => theme.below.xl} {
    height: 1.25rem;
  }
  svg {
    fill: currentColor;
  }
`;

const MobileWrapper = styled('div')`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 1.5rem;
`;
const SearchButton = ({
  onSearchOpen,
  searchIsOpen,
  triggerSearch,
  isDirty
}) => (
  <Above breakpoint="lg">
    {matches =>
      matches ? (
        <Container onClick={() => (isDirty ? triggerSearch() : onSearchOpen())}>
          <IconWrapper>
            <SearchIcon pose={searchIsOpen ? 'shifted' : 'default'} />
          </IconWrapper>
        </Container>
      ) : searchIsOpen ? null : (
        <MobileWrapper>
          <Icon onClick={onSearchOpen} />
        </MobileWrapper>
      )
    }
  </Above>
);

export default SearchButton;
