import t from '@jetshop/intl';
import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import theme from '@jetshop/ui/utils/theme';
import MaxWidth from './Layout/MaxWidth';
import Breadcrumbs from './ui/Breadcrumbs';

const Wrapper = styled(MaxWidth)`
  align-items: center;
  padding: 1rem;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  li {
    white-space: inherit;
  }
  padding: 0.625rem 0 0.125rem 0;
`;

const ErrorContainer = styled('div')`
  padding: 1.25rem;
  width: 60%;
  ${theme('below.md')} {
    width: 100%;
  }
`;

const ErrorTitle = styled('span')`
  font-style: normal;
  font-size: 4.375rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.errorPage.title};
  text-transform: uppercase;
  display: block;
`;

const ErrorContent = styled('span')`
  font-style: medium;
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.errorPage.content};
`;

const ErrorBody = styled('div')`
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.125rem;
  strong {
    font-weight: bold;
  }
  color: ${({ theme }) => theme.colors.errorPage.body};
`;

const P = styled('p')`
  margin-bottom: 0.9375rem;
  strong {
    font-weight: bold;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  text-align: center;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.errorPage.button.background};
  letter-spacing: 1px;
  text-transform: uppercase;
  :hover {
    background: ${({ theme }) => theme.colors.errorPage.button.backgroundHover};
  }
`;

const MailLink = styled('div')`
  text-align: center;
  font-weight: bold;
  margin-bottom: 1.25rem;
  a {
    color: ${({ theme }) => theme.colors.errorPage.link.color};
    line-height: inherit;
    text-decoration: none;
    :hover {
      color: ${({ theme }) => theme.colors.errorPage.link.colorHover};
    }
  }
`;

class NotFoundPage extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <StyledBreadcrumbs breadcrumbText=" " />
        <ErrorContainer>
          <ErrorTitle>{t('Whoops')}</ErrorTitle>
          <ErrorContent>
            {t(`You have attempted to download a page that does not exist on Jetshop's
            server.`)}
          </ErrorContent>
          <ErrorBody>
            {' '}
            <P>
              <strong>{t('This could be due to the following:')}</strong>
            </P>
            <P>
              {t(
                'The address provided is incorrect. Please check the spelling and try again.'
              )}
            </P>
            <P>
              {t(
                'You are trying to use an old address that no longer exists or that may have changed.'
              )}
            </P>
            <P>
              {t(
                'You may have used an old bookmark. If you are sure that you have used the correct address to the site, the problem may be caused by a temporary service disruption. Please try again later.'
              )}
            </P>
            <P>
              {t(
                'NOTE! If you were directed here via a link we are grateful if you inform us as to which link it was so that we can resolve the problem.'
              )}
            </P>
          </ErrorBody>
          <MailLink>
            <a href="mailto:info@evalds.se">{t('Mail us here')}</a>
          </MailLink>
          <StyledLink to="/">{t('HOMEPAGE')}</StyledLink>
        </ErrorContainer>
      </Wrapper>
    );
  }
}

export default NotFoundPage;
