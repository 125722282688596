import React, { Component } from 'react';
import styled from 'react-emotion';

const Wrapper = styled('section')`
  a {
    color: ${({ theme }) => theme.colors.footer.text};
    text-decoration: none;
    :hover {
      color: ${({ theme }) => theme.colors.footer.linkHover};
    }
  }
`;

const SectionHeader = styled('div')`
  color: ${({ theme }) => theme.colors.footer.text};
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 1rem;
  margin-top: 0.2rem;
  text-rendering: optimizeLegibility;
  text-transform: uppercase;
`;

const SectionContent = styled('div')`
  line-height: 1.6;
  > p {
    margin-bottom: 0.4375rem;
  }
`;

class FooterSection extends Component {
  render() {
    const props = {
      className: '',
      sectionTitle: '',
      children: '',
      ...this.props
    };
    return (
      <Wrapper className={props.className}>
        {props.sectionTitle && (
          <SectionHeader>{props.sectionTitle}</SectionHeader>
        )}
        <SectionContent>{props.children}</SectionContent>
      </Wrapper>
    );
  }
}

export default FooterSection;
